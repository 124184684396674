import styled from "styled-components";
import { fonts, landingPalette } from "../styles";


export const Hero = styled.header`
  padding: 2.4rem 0 8rem 0;

  article {
    padding: 0 80px 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 600px) {
      padding: 0 32px 0 32px;
    }

    @media (max-width: 425px) {
      padding: 0 16px 0 16px;
    }
  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    min-height: 44px;
    padding: 5px 20px;
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    margin-top: 20px;
    margin-bottom: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    position: relative; 
    z-index: 3;
  }

  .hero-main {
    position: relative;

    .hero-image {
      position: absolute;
      bottom: -90px;
      max-width: 1200px;
      max-height: 280px;
      width: 100%;
      z-index: 1;

      @media (max-width: 768px) {
        position: relative;
        bottom: 40px;
      }

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .hero-info {
    padding: 4rem 80px 6.7rem 80px;
    background-color: #F7F5F1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 300px;

    @media (max-width: 768px) {
      margin-top: -130px;
    }

    @media (max-width: 600px) {
      padding: 4rem 32px 6.7rem 32px;
    }

    @media (max-width: 425px) {
      padding: 4rem 16px 6.7rem 16px;
    }
  }

  .hero-info-container {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 6rem 8rem 4rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: unset;
    grid-gap: 3.2rem;
    position: relative;
    z-index: 2;

    @media (max-width: 980px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      padding: 4rem 4rem 4rem 3rem;
    }

    @media (max-width: 375px) {
      padding: 4rem 3.2rem 4rem 3.2rem;
    }
  }

  .hero-info-container article {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 16px;
    padding: 0;

    .hero-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      max-width: 45px;
      width: 100%;
      border-radius: 8px;
      background-color: #F7F5F1;
    }

    > div {
      margin-top: 10px;
    }
      
  }
`;

export const InfoSection = styled.section`
  padding: 8rem 80px 14rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 14rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 14rem 16px;
  }

  .item-section {
    display: flex;
    flex-direction: row;
    grid-gap: 5rem;
    justify-content: space-between;
    align-items: center;

  }

  .item-section-one {
    margin-bottom: 9rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    figure {
      max-width: 518px;
      width: 100%;
      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      h3 {
        max-width: 56rem;
      }

      p {
        max-width: 44rem;
      }
    }
  }

  .item-section-two {
    margin-bottom: 18rem;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }

    figure {
      max-width: 407px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      h3 {
        max-width: 44rem;
      }

      p {
        max-width: 44rem;
        margin-bottom: 4rem;
      }

      span {
        display: block;
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 4rem;
      }

      button,
      a {
        border: none;
        outline: none;
        background-color: ${landingPalette.orange};
        border-radius: 21px;
        height: 44px;
        padding: 0 30px;
        font-family: ${fonts.SofiaProMedium};
        font-size: 1.6rem;
        color: white;
        line-height: 2rem;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .subtitle-info {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 3rem;
    margin-bottom: 9rem;

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }

    h3 {
      font-size: 6.6rem;
      line-height: 3.9rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      display: flex;
      align-items: center;
      justify-content: center;

      .stars-title {
        margin-right: -3.5rem;
        margin-top: -3.5rem;
      }
    }

    p {
      font-size: 3.8rem;
      line-height: 4.8rem;
      width: 100%;
      max-width: 367px;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-top: -3rem;
    }
  }

  .info-list {
    display: flex;
    flex-direction: column;
    grid-gap: 3.2rem;
    max-width: 1280px;
    margin: auto;
  }
`;

export const HuntingGlobal = styled.section`
  position: relative;
  z-index: 1;

  @media (max-width: 1110px) {
    z-index: 0;
  }

  .map-section {
    padding: 8.4rem 80px 25rem 80px;
    position: relative;
    z-index: 1;

    @media (max-width: 600px) {
      padding: 8.4rem 32px 25rem 32px;
    }

    @media (max-width: 425px) {
      padding: 8.4rem 16px 25rem 16px;
    }
  }
    

  .main-landing-container-column {
    position: relative;
  }

  .figure-union {
    position: absolute;
    max-width: 12rem;
    width: 100%;
    right: 10%;
    top: 10rem;

    img {
      width: 100%;
      object-fit: scale-down;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .testimonials-section {
    padding: 0 80px 0 80px;
    position: absolute;
    bottom: -18rem;
    z-index: 2;
    width: 100%;

    @media (max-width: 1110px) {
      position: relative;
      bottom: unset;
      top: -25rem;
      margin-bottom: -35rem;
    }

    @media (max-width: 600px) {
      padding: 0 32px 0 32px;
    }

    @media (max-width: 425px) {
      padding: 0 16px 0 16px;
    }
      
  }

  .testimonial-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
    justify-content: space-around;
    grid-gap: 16px;
  }

  .main-landing-container-row {
    align-items: unset;
  }

  .testimonial-article {
    padding: 2.3rem;
    margin-bottom: 4rem;
    min-height: 250px;
    display: grid;
    background-color: white;
    border-radius: 8px;

    .testimonial-head {
      display: flex;
      grid-gap: 2rem;
      align-items: center;
      margin-bottom: 25px;

      figure {
        width: 64px;
        height: 64px;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
        
    }

    .testimonial-company-logo {
      max-width: 9rem;
      width: 100%;
      align-self: flex-end;
      justify-self: flex-end;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }
`;
export const FaqsContainer = styled.div`
  padding-top: 30rem;
`;

export const GetTalentSection = styled.section`
  padding: 0 80px 15rem 80px;

  @media (max-width: 600px) {
    padding: 0 16px 15rem 16px;
    
  }

  @media (max-width: 425px) {
    padding: 0 16px 15rem 16px;
  }

  .main-landing-container-row {
    justify-content: space-between;
    align-content: center;
    grid-gap: 5rem;
    display: flex;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
  }

 

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    height: 44px;
    padding: 0 30px;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }

  figure {
    max-width: 322px;
    width: 100%;
    margin-right: 60px;
      @media (max-width: 920px) {
          margin: 0 auto; 
          margin-bottom: 2rem; 
      }
    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`;
