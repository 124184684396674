import React from "react";
import styled from "styled-components";
import { fonts, landingPalette } from "../../styles/styles";
import { ArrowIosDownward, ArrowIosUpward } from "styled-icons/evaicons-solid";
import {Text } from "../ui/Text";

const FaqsSection = styled.section`
  /* z-index: 1; */
  position: relative;
  padding: 5rem 80px 18rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 18rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 3.2rem;
  }

  .faqs-list {
    display: flex;
    flex-direction: column;
  }

  .faqs-article-head {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding-bottom: 20px;
    outline: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .faqs-article {
    margin-bottom: 2rem;

    h4 {
      font-size: 2.2rem;
      line-height: 2.7rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 2rem;
      text-align: start;
    }

    .faqs-article-description {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: ${landingPalette.greyChevron};
      font-family: ${fonts.sofiaProRegular};
      margin-bottom: 2rem;
      max-width: 1250px;
    }

    .faqs-article-description-none {
      display: none;
    }

    hr {
      border: 1px solid #ede8ff;
      border-top: 0;
    }
  }
`;

export default function Faqs(props) {
  const { faqs = [] } = props;

  const [visibleAnswer, setVisibleAnswer] = React.useState(null);

  return (
    <FaqsSection {...props}>
      <div className="main-landing-container-column">
        <div className="faqs-list">
          {faqs.map((faq, idx) => (
            <article className="faqs-article" key={idx}>
              <button
                className="faqs-article-head"
                onClick={() => {
                  setVisibleAnswer(visibleAnswer === idx ? null : idx);
                }}
              >
                <Text.Body
                    text={faq.question}
                    fontSize="22px"
                    textAlign="start"
                />
                {visibleAnswer === idx ? (
                  <ArrowIosDownward size={16} color="#003D52" />
                ) : (
                  <ArrowIosUpward size={16} color="#003D52" />
                )}
              </button>
              <div
                className={
                  visibleAnswer === idx
                    ? "faqs-article-description"
                    : "faqs-article-description-none"
                }
              >
                <Text.Body
                    text={faq.answer}
                    fontSize="18px"
                    marginBottom="16px"
                />
              </div>
              <hr />
            </article>
          ))}
        </div>
      </div>
    </FaqsSection>
  );
}
